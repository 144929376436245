<template>
	<div>
		<template v-if="loadingWithdrawalRequests">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header">
				<div class="main__primary">
					<h3 v-if="showBankName">Withdrawal Requests</h3>
					<h3 v-else>New Withdrawal Requests</h3>
				</div>
				<div class="form__search-wrapper"></div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<p>Name</p>
						<p>Bank Name</p>
						<p>Amount</p>
						<p>Status</p>
						<p>Approval</p>
						<p>Created at</p>
						<p>Updated at</p>
					</div>
					<div class="contacts__body" v-if="withdrawalsRoute">
						<template v-if="withdrawalRequests.length > 0">
							<WithdrawalRequestItem
								v-for="withdrawalRequest in withdrawalRequests"
								:key="withdrawalRequest.id"
								:withdrawalRequestData="withdrawalRequest"
							/>
						</template>
						<template v-else-if="withdrawalRequests.length === 0">
							<p class="pl-5 mt-5">There are no withdrawal Request at the moment</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading Withdrawal Requests, please try again.
							</p>
						</template>
					</div>
					<div class="contacts__body" v-else>
						<template v-if="newWithdrawalRequests.length > 0">
							<WithdrawalRequestItem
								v-for="withdrawalRequest in newWithdrawalRequests"
								:key="withdrawalRequest.id"
								:withdrawalRequestData="withdrawalRequest"
								@approvalRequestButton="updateRequestMethod"
							/>
						</template>
						<template v-else-if="newWithdrawalRequests.length === 0">
							<p class="pl-5 mt-5">There are no withdrawal Request at the moment</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading Withdrawal Requests, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit && showBankName" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import WithdrawalRequestItem from './WithdrawalRequestItem';

export default {
	name: 'WithdrawalRequest',
	components: {
		Loader,
		Pagination,
		WithdrawalRequestItem
	},
	data() {
		return {
			offset: 0,
			limit: 10,
			baseUrl: 'withdrawalRequests',
			query: '',
			total: 0,
			queryError: '',
			loadingWithdrawalRequests: true,
			withdrawalsRoute: false,
			childApprovalRequestEvent: '',
			showBankName: true
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllWithdrawalRequests([this.limit, this.offset]);
		this.fetchNewWithdrawalRequest();
		if (this.$route.name === 'overview') {
			this.showBankName = false;
		}
	},
	watch: {
		status(value) {
			if (this.$route.name === 'withdrawalRequests') {
				this.withdrawalsRoute = true;
			}
			if (value === 'success') {
				if (this.childApprovalRequestEvent === 'approvalRequestButtonClicked') {
					this.fetchNewWithdrawalRequest();
					this.total = this.totalNewWithdrawalRequests;
					this.childApprovalRequestEvent = '';
				}
				this.loadingWithdrawalRequests = false;
				this.query = '';
				this.total = this.totalWithdrawalRequests;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllWithdrawalRequests([this.limit, this.offset]);
		}
	},
	computed: {
		...mapGetters({
			withdrawalRequests: 'getWithdrawalRequests',
			status: 'getStatus',
			totalWithdrawalRequests: 'getTotalWithdrawalRequests',
			newWithdrawalRequests: 'getNewWithdrawalRequests',
			totalNewWithdrawalRequests: 'getTotalNewWithdrawalRequests'
		})
	},
	methods: {
		...mapActions(['fetchAllWithdrawalRequests', 'fetchNewWithdrawalRequest']),
		updateRequestMethod(value) {
			this.childApprovalRequestEvent = value;
		}
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
</style>
