<template>
	<div>
		<div class="contacts--list__item">
			<!-- <label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label> -->
			<div class="contacts__info">
				<div class="info__primary">
					<p class="info__name">{{ fullName }}</p>
					<p class="info__email">{{ withdrawalRequestData.bank.account_number }}</p>
				</div>
			</div>
			<p class="info__email">
				{{ withdrawalRequest.bank.bank.name }}
			</p>
			<p class="info__address">
				{{ withdrawalRequest.amount | formatCurrency(withdrawalRequest.currency) }}
			</p>
			<p class="info__address">{{ withdrawalRequest.status }}</p>
			<p v-if="withdrawalRequest.status === 'NEW'" class="d-flex">
				<button
					@click.prevent="updateApproveRequest"
					class="btn-primary btn-sm w-50"
					:class="updateApproveRequestStyle"
					@click.exact="approvalEventFunc"
					type="button"
					name="approve"
				>
					Approve
				</button>
				<button
					@click.prevent="updateDeclineRequest"
					class="btn-primary btn-sm w-50"
					:class="updateDeclineRequestStyle"
					type="button"
					name="decline"
					@click.exact="approvalEventFunc"
				>
					Decline
				</button>
			</p>
			<p
				v-else-if="withdrawalRequest.status === 'APPROVED'"
				class="btn-success rounded-lg text-center btn-sm mt-1"
			>
				APPROVED
			</p>
			<p
				v-else-if="withdrawalRequest.status === 'DECLINED'"
				class="btn-danger rounded-lg text-center mr-3 btn-sm mt-1"
			>
				DECLINED
			</p>
			<p class="info__address">
				{{ withdrawalRequest.created_at | date }}
			</p>
			<p class="info__address">
				{{ withdrawalRequest.updated_at | date }}
			</p>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';

export default {
	name: 'WithdrawalRequestItem',
	mixins: [form],
	props: {
		withdrawalRequestData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			withdrawalRequest: this.withdrawalRequestData,
			itemData: '',
			mapStatus: '',
			showBankName: true
		};
	},
	created() {
		if (this.$route.name === 'overview') {
			this.showBankName = false;
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getWithdrawalRequest', 'getLoggedUser']),

		fullName() {
			return `${this.withdrawalRequestData.user.firstname} ${this.withdrawalRequestData.user.lastname}`;
		},
		updateApproveRequestStyle() {
			return ['btn__save--outline'];
		},
		updateDeclineRequestStyle() {
			return ['btn__danger'];
		}
	},
	filters: {
		date(val) {
			return moment(val).format('llll');
		}
	},
	methods: {
		...mapActions(['approveWithdrawalRequest']),
		updateApproveRequest() {
			this.withdrawalRequest.status = 'APPROVED';
			const data = {
				id: this.withdrawalRequest.id,
				status: 'APPROVED',
				admin_id: this.getLoggedUser.data.id
			};
			this.update(data);
		},
		updateDeclineRequest() {
			this.withdrawalRequest.status = 'DECLINED';
			const data = {
				id: this.withdrawalRequest.id,
				status: 'DECLINED',
				admin_id: this.getLoggedUser.data.id
			};
			this.update(data);
		},
		update(data) {
			this.approveWithdrawalRequest(data);
			this.approvalEventFunc();
		},
		approvalEventFunc() {
			this.$emit('approvalRequestButton', 'approvalRequestButtonClicked');
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>
